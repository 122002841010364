/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import { prop as propRemeda } from "remeda";
import { filterKeyInObject } from "@/helper/filter-object";

export const snakeString = (s: string | undefined): string => {
  if (s === undefined) return "";
  return s.replace(/[A-Z]/g, (v) => `_${v.toLowerCase()}`);
};

export const underscore = (o: any): any => {
  return filterKeyInObject(o, snakeString);
};

/**
 * @see https://remedajs.com/docs#prop
 */
export const prop = propRemeda;
