import { type FC, type ReactElement } from "react";
import { useRouter } from "next/router";
import { type SWRResponse } from "swr";
import { getApiState, type Context } from "@/api/swr/helpers/state";
import { LoaderDefinition } from "@/components/fragments/Loading";

interface Props {
  /**
   * このプロパティに渡したswrのレスポンスの一つでもエラーがある場合、エラー画面を表示する。
   */
  responses: readonly SWRResponse<any, any>[];
  context?: Context;
  children: ReactElement<any, any> | null;
  loader?: ReactElement<any, any> | null;
}

/**
 * @note
 * * `responses` の状態を見て、エラーハンドンリングやローディング画面表示のコントロールを行うので、
 * responsesに渡すのは
 *
 * 「一つでもエラーがあったら正常に動作することができない」もののみにするようにしてください。
 */
export const ApiStateController: FC<Props> = ({
  responses,
  context,
  children,
  loader,
}) => {
  const router = useRouter();
  const { error, isLoading } = getApiState(responses, context);

  if (error !== undefined) {
    // error boundaryに責務を移譲する
    throw error;
  }

  if (
    /* url slug の読み込みが可能になるまで待機 */
    !router.isReady ||
    isLoading
  ) {
    return loader ?? <LoaderDefinition type="pulse" />;
  }

  return children;
};
