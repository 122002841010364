import { useRouter } from "next/router";
import { watchKeys } from "./constants";
import { useSetDescribeEndpoint } from "./describe";
import { convertQuery } from "./helpers/convertQuery";
import { useMadrasAPI } from "@/api/hooks";
import { pickStringQueries } from "@/helper/next-js";
import {
  type MadrasAPIPagerReturn,
  type MadrasAPIOptions,
} from "@/api/hooks/use-madras-api/type";
import {
  type ApiPathsWithoutPathParam,
  type ApiPathsWithGetJsonMethod,
  type Methods,
} from "@/api/types";
import { splitObject } from "@/helper";
import { searchSettings } from "@/helper/local-storage";

/**
 * @note
 * * URLクエリストリングを一覧データ取得リクエストに反映する
 *   * 所定のURLクエリストリングが変更された場合、データを再取得する
 *   * URLクエリストリングを所定の形のクエリパラメータに変換し、一覧データ取得リクエストに付与する
 * * OpenAPIの型の強制的修正（v1 api のOpenAPI定義ではPagerが定義されていないため）
 * * describe エンドポイントの pathname を所定の atom へ設定
 * @prerequisite
 * * 引数 `api` が利用する GET endpoint が、ページングや検索に対応していること
 * * 引数 `api` が利用する GET endpoint について、describe エンドポイントが存在すること
 * @param api
 * @param option
 * @param queryFields fields[]に受け取りたいAPIレスポンスのキーを, max_nested_depthにesの深さを受け取るオブジェクト
 * @returns
 */
export function useList<
  Path extends ApiPathsWithoutPathParam & ApiPathsWithGetJsonMethod,
  Method extends Methods<Path> & "get",
>(
  path: Path,
  options?: Omit<MadrasAPIOptions<Path, Method, true>, "$Pager"> & {
    /**
     * describe エンドポイントがない場合、true にする
     */
    disableDescribe?: boolean;
  },
): MadrasAPIPagerReturn<Path, Method> {
  useSetDescribeEndpoint(path, options?.disableDescribe);

  const { query } = useRouter();
  const settings = searchSettings.get();
  const watchedQuery = pickStringQueries(watchKeys, query);

  const [{ sort }, restOption] = splitObject(
    ["sort"] as const,
    options ?? ({} as MadrasAPIOptions<Path, Method, true>),
  );

  const finalOption = {
    $Pager: true,
    ...restOption,
    params: {
      query: {
        ...convertQuery(watchedQuery, settings, sort),
        ...options?.params?.query,
      },
    },
  };

  return useMadrasAPI(
    path,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- 引数に応じて型を強制的に変更するため any を許容
    finalOption as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- 型に柔軟性を持たせるため許容。
  ) as any;
}
