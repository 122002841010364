/* eslint-disable no-nested-ternary */
import { useState, useEffect, type FC } from "react";
import styled from "@emotion/styled";
import { shallowEqual, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { useRouter } from "next/router";
import rison from "rison";
import { isDefined } from "remeda";
import { type RootState } from "@/modules/store";
import { snakeString } from "@/helper/underscore";
import { camelizeString } from "@/helper/camelize";
import {
  useDescribes,
  queryString,
  appendFilterKey,
} from "@/features/list-screen";
import {
  filterToArray,
  isFilterTypeComboBox,
} from "@/components/fragments/filter-tags/helpers";
import { findFilterTagLabel } from "@/helper";

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const TagContainer = styled.p`
  font-size: 13px;
  width: fit-content;
  margin: 0 10px 7px -5px;
  padding: 6px 32px 6px 11px;
  color: #4d4d4d;
  background-color: #d9d9d9;
  border-radius: 15px;
`;

const IconContainer = styled(ClearIcon)`
  margin: -5px 0 0 3px;
  position: absolute;
  transform: scale(0.6);
  cursor: pointer;
`;

const excludeKeys = [
  "gender",
  "status",
  "shippedAt.name",
  "totalArrivedAmount",
];
const expression = "=<>!";

const toyGenders = [
  { value: "1", name: "男の子用" },
  { value: "2", name: "女の子用" },
  { value: "9", name: "共通・該当外" },
];

const paymentStatus = [
  { value: "1", name: "成功" },
  { value: "2", name: "失敗" },
];

export type TagType = {
  key: string;
  value: string;
};

const FilterTags: FC<React.PropsWithChildren<unknown>> = () => {
  const router = useRouter();
  const { query, pathname } = router;
  const filterIn = query[queryString.FILTER_IN];

  const describesFromSWR = useDescribes();
  const describeFromStore = useSelector(
    (state: RootState) => state.describe,
    shallowEqual,
  );
  const describe = describesFromSWR ?? describeFromStore;

  const convertEndpointName = (tag: TagType) => {
    return (
      describe
        .find((item) => item.name === snakeString(tag.key))
        ?.data?.find((item) => item.uuid === tag.value)?.name ?? tag.value
    );
  };

  const convertExpression = (tag: TagType) => {
    if (typeof filterIn !== "string") {
      return "";
    }

    const oneOperandRightSide = findFilterTagLabel(
      filterIn,
      camelizeString(tag.value.slice(1)),
    ).toString();

    const twoOperandRightSide = findFilterTagLabel(
      filterIn,
      camelizeString(tag.value.slice(2)),
    ).toString();

    switch (tag.value[0]) {
      case "=":
        switch (tag.value[1]) {
          case "=":
            if (tag.value.slice(2) === "empty") {
              return "が空";
            }
            return `が${twoOperandRightSide}と等しい`;
          case ">":
            return `が${twoOperandRightSide}以上`;
          default:
            return "";
        }
      case "!":
        switch (tag.value[1]) {
          case "=":
            return `が${twoOperandRightSide}でない`;
          default:
            return "";
        }
      case "<":
        switch (tag.value[1]) {
          case "=":
            return `が${twoOperandRightSide}以下`;
          default:
            break;
        }
        return `が${oneOperandRightSide}より小さい`;
      case ">":
        return `が${oneOperandRightSide}より大きい`;
      default:
        return `：${tag.value}`;
    }
  };

  const convertCustomName = (tag: TagType) => {
    switch (tag.key) {
      case "gender":
        return `：${toyGenders.find((gender) => gender.value === tag.value)
          ?.name}`;

      case "status":
        return `：${paymentStatus.find((status) => status.value === tag.value)
          ?.name}`;
      case "shippedAt.name":
        return convertExpression(tag);
      case "totalArrivedAmount":
        return convertExpression(tag);
      default:
        return "";
    }
  };

  const filterToTagObj = (filters: string[]) => {
    const tagsObj: TagType[] = [];
    const regExp = /'/g;
    filters.forEach((filter) => {
      if (filter === "") {
        return;
      }
      if (filter.includes("[expression]")) {
        return;
      }
      if (filter.includes("[value]") || filter.includes("[uuid]")) {
        if (filter.includes("[start]")) {
          let value = filter.split("[value][start]:")[1]?.replace(regExp, "");
          if (isDefined(value)) {
            if (value.match(/T/)) {
              value = value.replace(/T/, " | ");
            }
            tagsObj.push({
              key: `${camelizeString(
                filter.split("[value][start]:")[0],
              )}.start`,
              value,
            });
          }
        } else if (filter.includes("[end]")) {
          let value = filter.split("[value][end]:")[1]?.replace(regExp, "");
          if (isDefined(value)) {
            if (value.match(/T/)) {
              value = value.replace(/T/, " | ");
            }
            tagsObj.push({
              key: `${camelizeString(filter.split("[value][end]:")[0])}.end`,
              value,
            });
          }
        } else {
          let key = camelizeString(filter.split("[")[0]);
          const value = filter.split("]:")[1]?.replace(regExp, "");
          if (isDefined(value) && typeof filterIn === "string") {
            if (expression.includes(value.charAt(0))) {
              if (typeof findFilterTagLabel(filterIn, key) === "object") {
                key = `${key}.name`;
              }
            }

            tagsObj.push({
              key,
              value,
            });
          }
        }
      } else if (!filter.includes("[type]")) {
        tagsObj.push({
          key: camelizeString(filter.split(":")[0]),
          value: String(filter.split(":")[1]?.replace(regExp, "")),
        });
      }
    });
    return tagsObj;
  };

  const [tags, setTags] = useState<TagType[]>([]);

  useEffect(() => {
    const filters = filterToArray(query.filter);

    const tagsObj = filterToTagObj(filters);

    setTags(tagsObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- 以前はexhaustive-depsは無視していたが、チェックするようになった。影響範囲が大きいので、ここは無視する。
  }, [query.filter]);

  const deleteFilterTag = (deleteTag: TagType) => {
    const remainTags = tags.filter(
      (tag) => !(tag.key === deleteTag.key && tag.value === deleteTag.value),
    );

    const duplicatedKeys = tags
      .filter((tag, index, array) => {
        const keys = array.map((item) => item.key);
        return (
          keys.indexOf(tag.key) === index && index !== keys.lastIndexOf(tag.key)
        );
      })
      .map((tag) => tag.key);
    let newQueryParams = {};
    if (query.filter !== undefined) {
      const filter: Record<string, string> = rison.decode_object(
        query?.filter?.toString() ?? "",
      );
      remainTags.forEach((remainTag) => {
        // if tag is date
        if (remainTag.key.includes(".")) {
          Object.entries(filter).forEach((param) => {
            if (
              param[0].split("[")[0] ===
                snakeString(remainTag.key.split(".")[0]) &&
              param[0].endsWith(`[${remainTag.key.split(".")[1]}]`)
            ) {
              newQueryParams = { ...newQueryParams, [param[0]]: param[1] };
            } else if (
              param[0].split("[")[0] ===
                snakeString(remainTag.key.split(".")[0]) &&
              (param[0].endsWith(`[value]`) ||
                param[0].endsWith(`[expression]`))
            ) {
              newQueryParams = { ...newQueryParams, [param[0]]: param[1] };
            }
          });
        } else {
          let num = 0;
          Object.entries(filter).forEach((param) => {
            if (
              duplicatedKeys.length > 0 &&
              duplicatedKeys.includes(remainTag.key)
            ) {
              if (param[1] === remainTag.value) {
                num += 1;
                if (num === 1) {
                  newQueryParams = {
                    ...newQueryParams,
                    [`${param[0].split("[")[0]}.type`]: "IN",
                  };
                }
                newQueryParams = {
                  ...newQueryParams,
                  [param[0]]: param[1],
                };
              }
            } else if (param[0].split("[")[0] === snakeString(remainTag.key)) {
              newQueryParams = { ...newQueryParams, [param[0]]: param[1] };
            }
          });
        }
      });
    }
    router.push({
      pathname,
      query: {
        ...appendFilterKey(query, newQueryParams),
        [queryString.FILTER_IN]: filterIn,
      },
    });
  };

  const getValue = (tag: TagType): string => {
    return isFilterTypeComboBox(tag)
      ? `：${convertEndpointName(tag)}`
      : !excludeKeys.includes(tag.key) && tag.key.slice(0, 2) === "is"
        ? `：${tag.value === "1" ? "有効" : "無効"}`
        : `：${tag.value}`;
  };

  return (
    <OuterContainer>
      {tags?.map((tag, i) => {
        if (typeof filterIn === "string") {
          return (
            <TagContainer key={i}>
              {findFilterTagLabel(filterIn, tag.key).toString()}
              {getValue(tag)}
              {convertCustomName(tag)}
              <IconContainer onClick={() => deleteFilterTag(tag)} />
            </TagContainer>
          );
        }
        return null;
      })}
    </OuterContainer>
  );
};
export default FilterTags;
